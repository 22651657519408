import React, { useState, useRef, useEffect } from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    Container,
    Button,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    Grid2,
    CircularProgress,
    IconButton,
} from '@mui/material';

import { useDropzone } from 'react-dropzone';
import { v1 as uuidv1 } from 'uuid';
import recosoftLogo from '../assets/images/logos/recosoft-logo.svg';
import pdf2officeLogo from '../assets/images/logos/PDF2Office-logo.png';
import removeIcon from '../assets/images/icons/delete.png';

import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';  // Import ReCAPTCHA component

let globalTokenCounter = 1;
let savedToken = null;
let lastProcessedConversionType = '';
const CONVERTED_FILES_KEY = 'ConvertedFiles';

const App = () => {
    const [files, setFiles] = useState([]);
    const inputRef = useRef(null);

    const [conversionType, setConversionType] = useState('pdf-to-word');
    
    // const [status, setStatus] = useState('');
    const [isConverting, setIsConverting] = useState(false); // Track conversion status

    const [sessionStartTime, setSessionStartTime] = useState(null);  // Track when the session starts

    const [isCaptchaVerified, setIsCaptchaVerified] = useState(false); // Whether CAPTCHA is verified
    const [captchaResponse, setCaptchaResponse] = useState(null);  // Store the captcha response

    useEffect(() => {
        localStorage.removeItem(CONVERTED_FILES_KEY);
    }, []); 
    
    const storeConvertedFiles = (token, filename, fileSize, lastModifiedDate) => {
        const convertedFiles = JSON.parse(localStorage.getItem(CONVERTED_FILES_KEY)) || [];
    
        const isDuplicate = convertedFiles.some(file => 
            file.filename === filename && 
            file.fileSize === fileSize && 
            file.lastModifiedDate === lastModifiedDate
        );
    
        if (!isDuplicate) {
            convertedFiles.push({ 
                token, 
                filename, 
                fileSize, 
                lastModifiedDate 
            });
    
            localStorage.setItem(CONVERTED_FILES_KEY, JSON.stringify(convertedFiles));
        } else {
            console.log("This file is already converted and stored.");
        }
    };
    
    // Retrieve the converted files (filenames and tokens) from localStorage
    const getConvertedFiles = () => {
        return JSON.parse(localStorage.getItem(CONVERTED_FILES_KEY)) || [];
    };

    const incrementGlobalTokenCounter = () => {
        globalTokenCounter += 1;
    };

    // Function to handle the captcha verification
    const handleCaptchaChange = (value) => {
        setCaptchaResponse(value);
    };


    // Reset session if expired or user clicks start conversion after session expiry
    const resetSession = () => {
        savedToken = null;
        globalTokenCounter = 1;
        setSessionStartTime(null);
        setCaptchaResponse(null);
        setIsCaptchaVerified(false);

        // Clear the CONVERTED_FILES_KEY from localStorage
        localStorage.removeItem(CONVERTED_FILES_KEY);
    };

    // Function to show the collected alerts
    const showAlerts = (messages) => {
        if (messages.length > 0) {
            alert(messages.join('\n'));  // Combine messages into one string with new lines and show the alert
        }
    };
     
    const onDrop = async (acceptedFiles) => {
        const invalidFiles = [];
        const validFiles = [];
        const alertMessages = [];

        for (const file of acceptedFiles) {
            const isPdf = await isPdfFile(file);
    
            if (!isPdf) {
                invalidFiles.push(file);
            } else {
                validFiles.push(file);
            }
        }
    
        if (invalidFiles.length > 0) {
            if (validFiles.length > 0) {
                alertMessages.push('Some files are not valid PDF files!');
            }
            else {
                alertMessages.push('This is not a valid PDF file!');
            }
        }

        // Filter files that exceed 5MB
        const largeFiles = validFiles.filter(file => file.size > 5 * 1024 * 1024); // 5MB in bytes
        const filesUnder5MB = validFiles.filter(file => file.size <= 5 * 1024 * 1024); // Valid files under 5MB
    
        if (largeFiles.length > 0) {
            alertMessages.push('Some files exceed the maximum size of 5 MB.');
        }

        if (filesUnder5MB.length === 0) {
            if (alertMessages.length > 0) {
                showAlerts(alertMessages);
            }
            return;
        }
    
        // Filter out files that are already added based on file name
        // const newFiles = validFiles.filter(file => {
        //     return !files.some(existingFile => existingFile.file.name === file.name);
        // });

        const newFiles = filesUnder5MB.filter(file => {
            return !files.some(existingFile => {
                return (
                    existingFile.file.name === file.name &&
                    existingFile.file.size === file.size &&             
                    existingFile.file.lastModified === file.lastModified
                );
            });
        });
        
        if (newFiles.length === 0) {
            alertMessages.push('One or more files have already been added.');
            if (alertMessages.length > 0) {
                showAlerts(alertMessages);  
            }
            return;
        }
    
        const filesToKeep = files.filter(fileObj => fileObj.status === 'Waiting');
        
        const currentFileCount = filesToKeep.length;
    
        if (currentFileCount + newFiles.length <= 5) {
            setFiles([
                ...filesToKeep,  // Keep only the files that are still "Waiting"
                ...newFiles.map(file => ({ file, status: 'Waiting' })),
            ]);
        } else {
            alertMessages.push('You can only upload a maximum of 5 files.');
        }

        if (alertMessages.length > 0) {
            showAlerts(alertMessages);
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            'application/pdf': ['.pdf'],
        }
        // maxSize: 5 * 1024 * 1024, // 5 MB
    });

    const isPdfFile = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
    
          reader.onloadend = () => {
            const uint = new Uint8Array(reader.result);
            // Check for the PDF file signature (%PDF-1.x)
            const isPdf = uint[0] === 0x25 && uint[1] === 0x50 && uint[2] === 0x44 && uint[3] === 0x46;
            resolve(isPdf);
          };
    
          reader.onerror = () => {
            reject('Error reading file');
          };
    
          // Read first 4 bytes (for PDF signature check)
          reader.readAsArrayBuffer(file.slice(0, 4));
        });
    };

    const handleRemove = (index) => {
        setFiles(files.filter((_, i) => i !== index));
    };

    const handleConversionTypeChange = (event) => {
        setConversionType(event.target.value);
    };

      // Function to set all file statuses to "Waiting"
    const setAllFilesToWaiting = () => {
        const updatedFiles = files.map(file => ({
        ...file,
        status: 'Waiting',
        }));
        setFiles(updatedFiles);
    };

    // Handle the Start Conversion button click
    // const uploadFiles = async () => {
    //     if (!files.length) {
    //         alert('Please select files.');
    //         return;
    //     }

    //     const waitingFiles = files.filter(fileObj => fileObj.status === 'Waiting');

    //     // if (waitingFiles.length === 0) {
    //     //     // If no files are "Waiting", show an alert to add new files
    //     //     alert('Files are already processed. Please add new files for conversion.');
    //     //     return;
    //     // }

    //     if(lastProcessedConversionType === '')
    //     {
    //         lastProcessedConversionType = conversionType;
    //     }

    //     /* //Do not Convert repeat files
    //     if (waitingFiles.length === 0) {
    //         // If no files are "Waiting", check if conversion type has changed
    //         if (lastProcessedConversionType !== conversionType) {
    //             // If conversion type has changed, allow processing to proceed
    //         } else {
    //             // If the conversion type hasn't changed, return without processing
    //             alert('Files are already processed. Please add new files for conversion.');
    //             return;
    //         }
    //     }
    //     */

    //     setAllFilesToWaiting();

    //     // if (!conversionType) {
    //     //     alert('Please select conversion type.');
    //     //     return;
    //     // }

    //     // Check if the session has expired
    //     if (sessionStartTime) {
    //         const currentTime = new Date().getTime();
    //         const sessionDuration = 10 * 60 * 1000;  // 10 minutes in milliseconds 

    //         // If the difference between current time and session start time is more than 10 minutes
    //         if (currentTime - sessionStartTime > sessionDuration) {
    //             resetSession();  // Reset session
    //             alert('Session is expired.');
    //             return;
    //         }
    //     }

    //     if (!savedToken) {
    //         // if(!isCaptchaVerified)
    //         // {
    //         //     if (!captchaResponse) {
    //         //         alert('Please complete the CAPTCHA!');
    //         //         return;
    //         //     }
    //         // }
            
    //         const newToken = await getToken();
    //         if (!newToken) return;
    //         savedToken = newToken
    //         setSessionStartTime(new Date().getTime());  // Set session start time
    //     }

    //     setIsConverting(true);

    //     for (let i = 0; i < files.length; i++) {
    //         // let fileToken = `${token}~${globalToken}`;

    //         let fileToken = savedToken.replace(/~\d+$/, `~${globalTokenCounter}`);

    //         const file = files[i].file;

    //         try {
    //             const uploadSuccess = await processFileUpload(file, fileToken, i);
    //             if (!uploadSuccess) {
    //                 // setStatus(`Upload failed for ${file.name}`);
    //                 continue;
    //             }
    //             else
    //             {
    //                 const filename = file.name;
    //                 const fileSize = file.size;
    //                 const lastModifiedDate = file.lastModified;
    //                 storeConvertedFiles(fileToken, filename, fileSize, lastModifiedDate); // Save to localStorage
    //             }

    //             const progressSuccess = await trackProgress(fileToken, i);
    //             if (!progressSuccess) {
    //                 // setStatus(`Conversion failed for ${file.name}`);
    //                 continue;
    //             }

    //             // await downloadFiles(fileToken);
    //             await downloadFiles(fileToken, i);
    //         } catch (error) {
    //             console.error('Error in file processing:', error);
    //         }

    //         incrementGlobalTokenCounter();
    //     }

    //     lastProcessedConversionType = conversionType;
    //     setIsConverting(false);
    // };


    const uploadFiles = async () => {
        if (!files.length) {
            alert('Please select files.');
            return;
        }

        const waitingFiles = files.filter(fileObj => fileObj.status === 'Waiting');

        // if (waitingFiles.length === 0) {
        //     // If no files are "Waiting", show an alert to add new files
        //     alert('Files are already processed. Please add new files for conversion.');
        //     return;
        // }

        if(lastProcessedConversionType === '')
        {
            lastProcessedConversionType = conversionType;
        }

        /* //Do not Convert repeat files
        if (waitingFiles.length === 0) {
            // If no files are "Waiting", check if conversion type has changed
            if (lastProcessedConversionType !== conversionType) {
                // If conversion type has changed, allow processing to proceed
            } else {
                // If the conversion type hasn't changed, return without processing
                alert('Files are already processed. Please add new files for conversion.');
                return;
            }
        }
        */

        setAllFilesToWaiting();

        // if (!conversionType) {
        //     alert('Please select conversion type.');
        //     return;
        // }

        setIsConverting(true);

        // Check if the session has expired
        if (sessionStartTime) {
            const currentTime = new Date().getTime();
            const sessionDuration = 10 * 60 * 1000;  // 10 minutes in milliseconds 

            // If the difference between current time and session start time is more than 10 minutes
            if (currentTime - sessionStartTime > sessionDuration) {
                resetSession();  // Reset session
                alert('Session is expired.');
                setIsConverting(false);
                return;
            }
        }

        if (!savedToken) {
            if(!isCaptchaVerified)
            {
                if (!captchaResponse) {
                    alert('Please complete the CAPTCHA!');
                    setIsConverting(false);
                    return;
                }
            }
            
            const newToken = await getToken();
            if (!newToken) return;
            savedToken = newToken
            setSessionStartTime(new Date().getTime());  // Set session start time
        }

        // setIsConverting(true);

        await Promise.all(
            files.map((fileObj, index) => {
                let fileToken = savedToken.replace(/~\d+$/, `~${globalTokenCounter}`);
                globalTokenCounter++;

                return processFile(fileObj, fileToken, index);
            })
        );

        lastProcessedConversionType = conversionType;
        setIsConverting(false);
    };

    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    const processFile = async (fileObj, fileToken, index) => {
        try {
            // const uploadSuccess = await processFileUpload(fileObj.file, fileToken, index);
            // if (!uploadSuccess) return;

            processFileUpload(fileObj.file, fileToken, index);
    
            await delay(3000);

            const progressSuccess = await trackProgress(fileToken, index);
            if (!progressSuccess) return;
    
            await downloadFiles(fileToken, index);
        } catch (error) {
            console.error('Error in processing the file:', error);
        }
    };


    const getToken = async () => {
        const newUUID = uuidv1();
        const clientId = newUUID;

        try {
            // const response = await axios.post('http://localhost:8000/api/getToken.php', {
            const response = await axios.post('https://portal-test.pdf2office.com/Converter/api/getToken.php', {
                clientId: clientId,
                recaptchaResponse: captchaResponse
            }, {
                timeout: 60000 // Timeout after 60 seconds
            });

            const data = response.data;

            if (data && data.token) {
                setIsCaptchaVerified(true);
                setCaptchaResponse(null); 
                return data.token;
            } else {
                // setStatus('Token not found in response.');
                alert('Failed to process the request. Please try again.');
                return null;
            }
        } catch (error) {
            alert('Failed to process the request. Please try again.');
            // setStatus('Failed to retrieve token: ' + error.message);
        }
    };

/*
    const processFileUpload = async (file, fileToken, index) => {
        const formData = new FormData();

        const convertedFiles = getConvertedFiles();

        const filename = file.name;
        const fileSize = file.size;
        const lastModified = file.lastModified;

        const existingConvertedFile = convertedFiles.find(
            (convertedFile) =>
                convertedFile.filename === filename &&
                convertedFile.fileSize === fileSize &&
                convertedFile.lastModifiedDate === lastModified
        );

        if (existingConvertedFile) {
            const duplicateFile = convertedFiles.filter(
                (convertedFile) => convertedFile.filename === filename
            );

            if (duplicateFile.length > 1) {
                formData.append('file', file);
            } else {
                const existingToken = existingConvertedFile.token;
                let lastTildeIndex = existingToken.lastIndexOf('~');
                const existingTokenWithoutIndex = existingToken.slice(0, lastTildeIndex);

                lastTildeIndex = fileToken.lastIndexOf('~');
                const currentTokenWithoutIndex = fileToken.slice(0, lastTildeIndex);

                if (existingTokenWithoutIndex === currentTokenWithoutIndex) {
                    formData.append('filename', filename);
                }
                else
                {
                    formData.append('file', file);
                }
            }
        } else {
            formData.append('file', file);
        }

        formData.append('conversionType', conversionType); // Add the conversion type to the request    
        try {
            setFileStatus(index, 'Uploading...');
    
            // const response = await axios.post('http://localhost:8000/api/uploadFile.php', formData, {
            const response = await axios.post('https://portal-test.pdf2office.com/Converter/api/uploadFile.php', formData, {
                headers: {
                    'Authorization': `Bearer ${fileToken}`,
                    'Content-Type': 'multipart/form-data', 
                },
                timeout: 300000, // Set timeout to 300 seconds (5 mins)
            });
    
            const result = response.data;
    
            if (result.status && result.status.toLowerCase() === 'success') {
                setFileStatus(index, 'Converting...');
                return true;
            }
            else if (result.status && result.status.toLowerCase() === 'failed') {
                const statusMessage = `Upload Failed (${result.error || 'Unknown error'})`;
                setFileStatus(index, statusMessage);
                return false;
            } else {
                const statusMessage = `Upload failed)`;
                setFileStatus(index, statusMessage);
                return false;
            }
        } catch (error) {
            console.error('Upload error:', error);
    
            // let statusMessage = ``

            // // Handle error cases
            // if (error.response) {
            //     // Server responded with a status code outside 2xx
            //     statusMessage = `Upload failed with catch (response other than 200))`
            // } else if (error.request) {
            //     // No response was received
            //     statusMessage = `Upload failed with catch (No response from server))`
            // } else {
            //     // Something went wrong in setting up the request
            //     statusMessage = `Upload failed with catch (somethng wrong))`
            // }

            // setFileStatus(index, statusMessage);
    
            setFileStatus(index, 'Upload failed');
            return false;
        }
    };
*/    


    const processFileUpload = async (file, fileToken, index) => {
        const formData = new FormData();

        const convertedFiles = getConvertedFiles();

        const filename = file.name;
        const fileSize = file.size;
        const lastModified = file.lastModified;

        const existingConvertedFile = convertedFiles.find(
            (convertedFile) =>
                convertedFile.filename === filename &&
                convertedFile.fileSize === fileSize &&
                convertedFile.lastModifiedDate === lastModified
        );

        if (existingConvertedFile) {
            const duplicateFile = convertedFiles.filter(
                (convertedFile) => convertedFile.filename === filename
            );

            if (duplicateFile.length > 1) {
                formData.append('file', file);
            } else {
                const existingToken = existingConvertedFile.token;
                let lastTildeIndex = existingToken.lastIndexOf('~');
                const existingTokenWithoutIndex = existingToken.slice(0, lastTildeIndex);

                lastTildeIndex = fileToken.lastIndexOf('~');
                const currentTokenWithoutIndex = fileToken.slice(0, lastTildeIndex);

                if (existingTokenWithoutIndex === currentTokenWithoutIndex) {
                    formData.append('filename', filename);
                }
                else
                {
                    formData.append('file', file);
                }
            }
        } else {
            formData.append('file', file);
        }

        formData.append('conversionType', conversionType); // Add the conversion type to the request    
        setFileStatus(index, 'Uploading...');
    
        // axios.post('http://localhost:8000/api/uploadFile.php', formData, {
        axios.post('https://portal-test.pdf2office.com/Converter/api/uploadFile.php', formData, {
            headers: {
                'Authorization': `Bearer ${fileToken}`,
                'Content-Type': 'multipart/form-data', 
            },
            // timeout: 300000, // Set timeout to 300 seconds (5 mins)
        });
    };


    const trackProgress = async (token, index) => {
        setFileStatus(index, 'Converting...');
        return new Promise((resolve, reject) => {
            const checkProgress = async () => {
                try {
                    // const response = await axios.get('http://localhost:8000/api/getConversionStatus.php', {
                    const response = await axios.get('https://portal-test.pdf2office.com/Converter/api/getConversionStatus.php', {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                    });
    
                    const { status: conversionStatus, error: conversionError } = response.data;
    
                    if (conversionStatus.toLowerCase() === 'completed') {
                        // setFileStatus(index, 'Completed');
                        setFileStatus(index, 'Downloading...');
                        resolve(true); 
                    } else if (conversionStatus.toLowerCase() === 'failed') {
                        const statusMessage = `Failed (${conversionError ? conversionError : 'Unknown error'})`;
                        setFileStatus(index, statusMessage);
                        reject(new Error('Failed'));
                    } else {
                        setTimeout(checkProgress, 3000);  // Recursively call checkProgress after 1.5 seconds
                    }
                } catch (error) {
                    console.error('Error fetching progress:', error);
                    setFileStatus(index, 'Progress Error');
                    reject(new Error('Error during progress check.'));
                }
            };
    
            checkProgress();
        });
    };


    const downloadFiles = async (token, index) => {  // Pass index as parameter for correct context
        try {
            // const response = await axios.get('http://localhost:8000/api/new_downloadFile.php', {
            const response = await axios.get('https://portal-test.pdf2office.com/Converter/api/downloadFile.php', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                responseType: 'blob',  // Ensure the response is treated as a Blob (binary data)
                timeout: 120000  // Timeout set to 120,000 milliseconds (120 seconds)
            });
    
            if (response.status === 200) {
                const disposition = response.headers.get('Content-Disposition');
                let filename = 'downloaded_file'; // Default filename

                const filenamePattern = /filename\*=UTF-8''([^;]+)/;
                const matches = disposition && disposition.match(filenamePattern);
          
                if (matches) {
                    filename = decodeURIComponent(matches[1]);
                }
          
                console.log('Filename:', filename);

                const url = window.URL.createObjectURL(response.data);
                
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = filename;
        
                document.body.appendChild(a);
                a.click();

                setFileStatus(index, 'Completed');

                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            } else {
                setFileStatus(index, 'Downloading failed');
                console.error(`Download failed with status code: ${response.status}`);
            }
        } catch (error) {
            setFileStatus(index, 'Downloading failed');
    
            if (error.code === 'ECONNABORTED') {
                console.error('Request timed out');
            } else {
                console.error('Error downloading file:', error);
            }
        }
    };
    

    // Helper function to set the status of a file
    const setFileStatus = (index, status) => {
        setFiles(prevFiles => {
            const updatedFiles = [...prevFiles];
            updatedFiles[index].status = status;
            return updatedFiles;
        });
    };

    
    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <AppBar position="static" style={{ background: '#f1f1f1' }}>
                <Toolbar>
                    <img src={recosoftLogo} alt="Company Logo" style={{ height: '40px', marginRight: '10px' }} />
                </Toolbar>
            </AppBar>

            <Container style={{ flexGrow: 1 }}>
                <Box textAlign="center" marginY={4}>
                    <img src={pdf2officeLogo} alt="App Logo" />
                    <Typography variant="h5">Convert PDF to Office</Typography>
                </Box>

                <Grid2 container spacing={2} justifyContent="center" alignItems="center">
                    <Grid2 item>
                        <Typography variant="subtitle1">Conversion type:</Typography>
                    </Grid2>
                    <Grid2 item>
                        <Select
                            value={conversionType} 
                            onChange={handleConversionTypeChange}
                            disabled={isConverting} 
                            displayEmpty
                            sx={{
                                height: 40,  
                                fontSize: '16px',  
                                padding: '0 12px',  
                            }}
                        >
                            <MenuItem value="pdf-to-word">Microsoft Word</MenuItem>
                            <MenuItem value="pdf-to-excel">Microsoft Excel</MenuItem>
                            <MenuItem value="pdf-to-ppt">Microsoft PowerPoint</MenuItem>
                            <MenuItem value="pdf-to-text">Text</MenuItem>
                            <MenuItem value="pdf-to-images">Extract Images</MenuItem>
                        </Select>
                    </Grid2>
                </Grid2>

                <Grid2 item xs={12}>
                    <div
                        {...getRootProps({
                            className: 'dropzone',
                            style: { 
                                border: '2px dashed #ccc', 
                                padding: '20px', 
                                marginTop: '20px', 
                                textAlign: 'center',
                                opacity: isConverting ? 0.5 : 1,  
                                pointerEvents: isConverting ? 'none' : 'auto',  
                            }
                        })}
                    >
                        <input {...getInputProps()} />
                        <Typography 
                            variant="body1" 
                            sx={{ marginBottom: 2 }}
                        >
                            Drag and drop a PDF file to use our PDF to Office converter. (Maximum 5 files and maximum file size allowed is 5 MB)
                        </Typography>
                        <Button 
                            variant="contained" 
                            color="primary" 
                            onClick={() => inputRef.current && inputRef.current.click()} 
                            sx={{
                                height: 40, 
                                fontSize: '16px',
                                padding: '0 25px',
                            }}
                            disabled={isConverting} 
                        >
                            Select Files
                        </Button>
                    </div>
                </Grid2>

                {/* Conditionally render the table and the Start Conversion button */}
                {files.length > 0 && (
                    <>
                        <TableContainer component={Paper} sx={{ width: '100%', marginTop: '30px' }}>
                            <Table sx={{ borderCollapse: 'collapse' }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ width: '50px', height: '15px', padding: '8px', verticalAlign: 'middle' }}>Sr</TableCell>
                                        <TableCell sx={{ width: '500px', height: '15px', padding: '8px', verticalAlign: 'middle' }}>File Name</TableCell>
                                        <TableCell sx={{ width: '120px', height: '15px', padding: '8px', verticalAlign: 'middle' }}>Size (MB)</TableCell>
                                        <TableCell sx={{ width: '150px', height: '15px', padding: '8px', verticalAlign: 'middle' }}>Status</TableCell>
                                        <TableCell sx={{ width: '50px', height: '15px', padding: '10px', verticalAlign: 'middle', textAlign: 'center' }}>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {files.map((fileObj, index) => (
                                        <TableRow key={index}>
                                            <TableCell sx={{ width: '50px', height: '10px', padding: '8px', verticalAlign: 'middle' }}>{index + 1}</TableCell>
                                            <TableCell sx={{ width: '500px', height: '10px', padding: '8px', verticalAlign: 'middle' }}>{fileObj.file.name}</TableCell>
                                            <TableCell sx={{ width: '120px', height: '10px', padding: '8px', verticalAlign: 'middle' }}>
                                                {(fileObj.file.size / 1024 / 1024).toFixed(2)}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                width: '150px',
                                                height: '10px',
                                                padding: '8px',
                                                verticalAlign: 'middle',
                                                color: fileObj.status.toLowerCase().includes('failed') ? 'red' : 'inherit', // Apply red color if status contains 'failed'
                                                }}
                                            >
                                                {fileObj.status}
                                                {(fileObj.status.toLowerCase().includes('uploading') || fileObj.status.toLowerCase().includes('converting')) && (
                                                <CircularProgress size={24} style={{ marginLeft: 10 }} />
                                                )}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                width: '50px',
                                                height: '10px',
                                                padding: '8px',
                                                verticalAlign: 'middle',
                                                textAlign: 'center', 
                                                lineHeight: 'normal', 
                                                }}
                                            >
                                                <IconButton
                                                onClick={() => handleRemove(index)}
                                                aria-label="remove file"
                                                style={{
                                                    padding: '0', 
                                                    backgroundColor: isConverting ? '#f1f1f1' : 'transparent', 
                                                    cursor: isConverting ? 'not-allowed' : 'pointer', 
                                                }}
                                                disabled={isConverting}
                                                >
                                                <img
                                                    src={removeIcon} 
                                                    alt="Remove"
                                                    style={{
                                                    width: '18px',
                                                    height: '18px',
                                                    opacity: isConverting ? 0.5 : 1, 
                                                    filter: isConverting ? 'grayscale(100%)' : 'none', 
                                                    }}
                                                />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Box textAlign="center" marginY={4}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={uploadFiles}
                                disabled={!conversionType || !files.length || isConverting}
                                sx={{
                                    height: 40,  
                                    fontSize: '16px', 
                                    padding: '0 25px',  
                                }}
                            >
                                Start Conversion
                            </Button>
                        </Box>      
                    </>
                )}

                {!isCaptchaVerified && (
                    <Box textAlign="center" marginY={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <ReCAPTCHA
                            sitekey="6LfmySUqAAAAAK55uWzlBenZ-kYq9CQGRQ5FtCwc"  
                            onChange={handleCaptchaChange}
                        />
                    </Box>
                )}   

                {/* {status && <Typography variant="body2" style={{ marginTop: '20px' }}>{status}</Typography>} */}
            </Container>

            <footer style={{ background: '#f1f1f1', padding: '10px', textAlign: 'center' }}>
                <Typography variant="body2">© Recosoft Corporation 2024</Typography>
            </footer>
        </div>
    );
};

export default App;
